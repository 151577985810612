var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-toolbar-title',{staticClass:"grey--text text--darken-4 font-weight-black my-5 ml-12"},[_vm._v(" Control Drug Admin List ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"filter_alt","label":"Filter By Resident","single-line":"","hide-details":""},model:{value:(_vm.searchResident),callback:function ($$v) {_vm.searchResident=$$v},expression:"searchResident"}},'v-text-field',attrs,false),on))]}}])},[_c('v-list',{staticStyle:{"max-height":"100px"},model:{value:(_vm.searchResident),callback:function ($$v) {_vm.searchResident=$$v},expression:"searchResident"}},_vm._l((_vm.residents),function(item,index){return _c('v-list-item',_vm._b({key:index},'v-list-item',item,false),[_c('v-list-item-title',{on:{"click":function($event){return _vm.controlDrugByResident(item)}}},[_vm._v(_vm._s(item.fullName))])],1)}),1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-23",attrs:{"headers":_vm.headers,"items":_vm.controlDrug,"options":_vm.options,"server-items-length":_vm.totalControlDrug,"loading":_vm.loading,"loading-text":"Loading ..... Please wait","footer-props":{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Control Drug per page',
          },"update:options":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Control Drug name"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Directive"},model:{value:(_vm.editedItem.directive),callback:function ($$v) {_vm.$set(_vm.editedItem, "directive", $$v)},expression:"editedItem.directive"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Witness"},model:{value:(_vm.editedItem.witness),callback:function ($$v) {_vm.$set(_vm.editedItem, "witness", $$v)},expression:"editedItem.witness"}})],1),_c('v-datetime-picker',{attrs:{"label":"Date & Time Given","timePickerFormat":"24hr","dateFormat":"dd-MM-yyyy"},model:{value:(_vm.editedItem.givenDateTime),callback:function ($$v) {_vm.$set(_vm.editedItem, "givenDateTime", $$v)},expression:"editedItem.givenDateTime"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"space-around"}},[_c('span',{staticClass:"group pa-2"},[(_vm.$can('update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]):_vm._e(),(_vm.$can('delete'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")]):_vm._e(),(_vm.$can('read'))?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.detail_control_drug_admin(item)}}},[_vm._v("info")]):_vm._e()],1)])]}}])}),(_vm.selectedId)?_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addNew()}}},on),[_vm._v(" Register ")])]}}],null,false,366135196)},[_c('span',[_vm._v("Register new control drug")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }